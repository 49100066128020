<template>
  <div>

  </div>
</template>

<script>
//获取用户的OPEN_ID
import {Loading} from 'element-ui'
import {requestUserOpenId} from '../api/user'

export default {
  name: 'Link',
  data() {
    return {
      code: this.$route.query.code,
    }
  },
  created() {
    if (this.code) {
      this.loadingInstance = Loading.service({fullscreen: true, spinner: 'el-icon-loading'})
      requestUserOpenId(this.code).then(res => {
        if (res.data.code === 0) {
          var result = res.data.data
          let open_id = result.openid
          this.$router.replace({
            path: '/login',
            query: {
              open_id: open_id
            }
          })
          this.loadingInstance.close()
        } else {
          this.loadingInstance.close()
          this.$message.error('获取用户信息出错')
        }
      })
    } else {
      this.$message.error('未获取到code授权')
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>
