import axios from "axios";

export function loginByStudentService(params) {
  return axios.post("./index.php", {
    function: "loginByStudentService",
    ...params,
  });
}

export function loginByContactService(params) {
  return axios.post("./index.php", {
    function: "loginByContactService",
    ...params,
  });
}

export function requestUserOpenId(code) {
  return axios.post("./index.php", {
    function: "requestUserOpenId", code
  });
}
